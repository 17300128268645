<template>
  <div class="global-search">
    <div class="filter">
      <span class="global-search-header">Filter</span>

      <b-form-checkbox-group class="tag-selection" v-model="tags">
        <b-form-checkbox v-for="tag in availableTags" :key="tag" :value="tag">
          {{ tagName(tag) }}

          <span v-if="result">({{ totalForTag(tag) }})</span>
        </b-form-checkbox>
      </b-form-checkbox-group>
    </div>

    <ul class="results">
      <span class="global-search-header" v-if="result">
        Ergebnisse für <em>{{ result.search }}</em>
      </span>

      <template v-if="result">
        <em v-if="result.data.length == 0">Keine Ergebnisse gefunden</em>

        <GlobalSearchResult
          v-for="item in sliceVisibleResults(result.data)"
          :key="getIterationKey(item)"
          :value="item"
          tabindex="0"
        />

        <b-button
          class="all-results-btn"
          v-if="showMoreVisible(result.data)"
          @click="navigateToFullPage()"
          variant="orange"
        >
          Alle {{ result.paginatorInfo.total }} Ergebnisse anzeigen
        </b-button>
      </template>

      <b-pagination
        v-if="shouldShowPagination()"
        :value="page"
        :total-rows="result.paginatorInfo.total"
        :per-page="result.paginatorInfo.perPage"
        pills
        align="center"
        @input="(n) => (page = n)"
      >
      </b-pagination>
    </ul>
  </div>
</template>

<script>
import GlobalSearchResult from "./GlobalSearchResult.vue";
import gql from "graphql-tag";

const tagMapping = {
  GREMIUM: {
    index: "gremiums",
    name: "Gremien",
  },
  NEWS: {
    index: "news",
    name: "News",
  },
  USER: {
    index: "users",
    name: "Benutzer",
  },
  TOPIC_COMMENT: {
    index: "topic_comments",
    name: "Kommentare",
  },
  FORUM_TOPIC: {
    index: "forum_topics",
    name: "Beiträge",
  },
  FILE: {
    index: "files",
    name: "Dateien",
  },
  COMPANY: {
    index: "companies",
    name: "Firmen",
  },
  DSAG_EVENT: {
    index: "dsag_events",
    name: "Veranstaltungen",
  },
  PARTNER_PROFILE: {
    index: "partner_profiles",
    name: "Partnerfirmen",
  },
};

const tagNames = Object.keys(tagMapping);

function parseQuery(query) {
  const raw_page = parseInt(query.page, 10);
  const valid_page = typeof raw_page === "number" && !isNaN(raw_page);

  const tags = (query.tags ?? "").split(",").filter((key) => {
    return tagNames.includes(key);
  });

  const raw_count = parseInt(query.count, 10);
  const valid_count = typeof raw_count === "number" && !isNaN(raw_count);

  return {
    page: valid_page ? raw_page : 1,
    count: valid_count ? raw_count : 10,
    tags: tags,
  };
}

export default {
  name: "GlobalSearchPreview",
  props: ["searchQuery", "previewMode"],
  components: {
    GlobalSearchResult,
  },
  data() {
    const query = parseQuery(this.$route.query);

    return {
      availableTags: tagNames,
      page: !this.previewMode ? query.page : 1,
      count: !this.previewMode ? query.count : 10,
      tags: !this.previewMode ? query.tags : [],
    };
  },
  watch: {
    tags() {
      if (!this.previewMode) {
        const query = Object.assign({}, this.$route.query, {
          tags: this.tags.join(","),
        });

        this.$router.push({
          path: this.$route.path,
          query: query,
        });
      }
    },
    $route() {
      const query = parseQuery(this.$route.query);

      if (this.previewMode) {
        this.page = query.page;
        this.count = query.count;
        this.tags = query.tags;
      }
    },
  },
  methods: {
    tagToIndex: (tag) => tagMapping[tag]?.index,
    tagName: (tag) => tagMapping[tag]?.name,
    totalForTag(tag) {
      const key = this.tagToIndex(tag);
      const totals = this.result.totalPerIndex;
      return key in totals ? totals[key] : 0;
    },
    getIterationKey(item) {
      if (!("id" in item)) {
        throw new Error(`Cannot get iteration key for ${item}`);
      }

      return item.id;
    },
    sliceVisibleResults(results) {
      // TODO Determine the maximum amount of items via the available space.
      return this.previewMode ? results.slice(0, 4) : results;
    },
    showMoreVisible(results) {
      const everythingVisible =
        results.length == this.sliceVisibleResults(results).length;

      return this.previewMode && !everythingVisible;
    },
    shouldShowPagination() {
      const result = this.result;

      return (
        !this.previewMode &&
        result &&
        result.data.length > 0 &&
        result.paginatorInfo.total > result.data.length
      );
    },
    navigateToFullPage() {
      this.$router.push({
        name: "global-search",
        query: {
          query: this.searchQuery,
          page: 1,
          tags: this.tags.join(","),
        },
      });
    },
  },
  apollo: {
    result: {
      fetchPolicy: "network-only",
      prefetch: false,
      query: gql`
        query (
          $search: String
          $page: Int
          $count: Int
          $tags: [GlobalSearchTag]
        ) {
          globalSearch(
            search: $search
            page: $page
            count: $count
            tags: $tags
          ) {
            search

            paginatorInfo {
              total
              count
              currentPage
              perPage
            }

            totalPerIndex {
              gremiums
              news
              users
              topic_comments
              forum_topics
              dsag_events
              files
              companies
              partner_profiles
            }

            data {
              __typename

              ... on Gremium {
                id
                name
                route_slug
              }

              ... on News {
                id
                title
                route_slug

                gremium {
                  id
                  route_slug
                }
              }

              ... on User {
                id
                first_name
                last_name
                email

                company {
                  id
                  name1
                }
              }

              ... on TopicComment {
                id
                user {
                  id
                  first_name
                  last_name
                }
                content {
                  id
                  text_content
                }
                topic {
                  id
                  route_slug
                  forum {
                    id
                    gremium_route_slug
                  }
                }
              }

              ... on File {
                id
                name
                description
              }

              ... on Company {
                id
                name1
              }

              ... on CompanyPartnerProfile {
                id
                comp: company {
                  id
                  name1
                }
              }

              ... on ForumTopic {
                id
                title
                route_slug
                forum {
                  id
                  gremium_route_slug
                }
              }

              ... on DsagEvent {
                id
                name
                route_slug
              }
            }
          }
        }
      `,
      update(data) {
        return data.globalSearch;
      },
      debounce: 300,
      variables() {
        return {
          search: this.searchQuery,
          tags: this.tags,
          page: this.page,
          count: this.count,
        };
      },
    },
  },
};
</script>
